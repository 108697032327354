<template>
    <div class="page-aftersale-upload-img">
        <div class="count-box" v-if="countArr.length">
            <div class="count-item" v-for="(item, index) in countArr" :key="index">
                <div class="warning-name" v-if="item.tagId == 50">无法读取数据</div>
                <div class="warning-name" v-if="item.tagId == 51">气管破损</div>
                <div class="item-text">总数: {{item.totalSize}}</div>
                <div class="item-text">已验收: {{item.checkedSize}}</div>
                <div class="item-text">剩余: <span>{{item.totalSize - item.checkedSize}}</span></div>
            </div>
        </div>
        <div class="list-box">
            <div class="table-header">
                <div class="left">故障类型</div>
                <div class="center">数量</div>
                <div class="right">验收日期</div>
            </div>
            <div class="list-item" v-for="(item, index) in list" :key="index">
                <div class="left" v-if="item.tagId == 50">无法读取数据</div>
                <div class="left" v-if="item.tagId == 51">气管破损</div>
                <div class="center">{{item.size}}个</div>
                <div class="right">{{item.time}}</div>
            </div>
            <div class="loading-box">
                <Button
                    loading
                    v-if="loading && !isShowAll"
                    type="default"
                    loading-text="加载中..."
                />
                <Button
                    type="default"
                    v-if="!loading && !isShowAll"
                    @click="loadMore"
                >点击加载更多</Button>
                <Button
                    type="default"
                    v-if="isShowAll"
                >{{list.length ? '已全部显示' : '暂无数据'}}</Button>
            </div>
        </div>

        <div class="create-btn" @click="showModal = true">
            <div>新建</div>
            <div>验收</div>
        </div>

        <Popup v-model="showModal" @close="initData">
            <div class="modal-box">
                <div class="modal-header" v-if="step == 1">请选择核对的故障类型</div>
                <div class="modal-header" v-if="step == 2">请输入此次验收的数量</div>
                <div class="modal-body">
                    <div :class="{'select-item': true, 'active': tagId == 50}" @click="tagId = 50" v-if="step == 1">无法读取数据</div>
                    <div :class="{'select-item': true, 'active': tagId == 51}" @click="tagId = 51" v-if="step == 1">气管破损</div>
                    <Field v-model="checkNum" type="number" v-if="step == 2" input-align="center" placeholder="请输入数量"/>
                </div>
                <div class="modal-footer">
                    <div class="btn btn-next" v-if="step == 1" @click="next">下一步</div>
                    <div class="btn btn-prev" v-if="step == 2" @click="step = 1">上一步</div>
                    <div :class="{'btn': true, 'btn-sure': true, 'pass': isPass}" @click="postNum" v-if="step == 2">确认</div>
                </div>
            </div>
        </Popup>
    </div>
</template>

<script>
import { Toast, Button, Popup, Field } from "vant";
import { getYMD } from '@/utils/time'
export default {
    name: "AcceptanceCheckWarningList",
    components: {
        Button,
        Popup,
        Field
    },
    data() {
        const { ossHost } = this.$config.base;
        return {
            ossHost,
            list: [],
            isShowAll: false,
            loading: false,
            countArr: [],
            showModal: false,
            checkNum: '',
            step: 1,
            tagId: 0
        };
    },
    created() {
        const vm = this;
        // const { USER_NAME } = vm.$config.keys;
        // let name = vm.$localStorage.getItem(USER_NAME);
        const uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
        const fType = vm.$strTool.filterFactoryType(uuid);
        if (fType != 'lansong') {
            vm.$router.replace({
                path: "404"
            });
            return
        }
        vm.pageNo = 1
        vm.loadList();
        vm.loadCount();
    },
    computed: {
        isPass() {
            return this.checkNum > 0
        }
    },
    methods: {
        loadList() {
            const vm = this;
            const params = {
                pageNo: vm.pageNo,
                pageSize: 20
            }
            vm.loading = true
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/check`,
                params,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                res.data.data.map(item => {
                    item.time = getYMD(item.createAt);
                })
                vm.list = vm.pageNo == 1 ? res.data.data : vm.list.concat(res.data.data);
                vm.isShowAll = res.data.data.length < 20
            });
        },

        initData() {
            this.tagId = 0;
            this.checkNum = '';
            this.step = 1;
        },

        next() {
            const vm = this
            if (!vm.tagId) {
                Toast("请选择故障类型");
                return
            }
            vm.step = 2;
        },

        postNum() {
            const vm = this
            if (!vm.checkNum) {
                return
            }
            vm.tt = Toast.loading('加载中')
            vm.$http({
                type: "post",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/check`,
                data: {
                    password: vm.$config.base.password,
                    size: vm.checkNum,
                    tagId: `${vm.tagId}`
                }
            }).then(res => {
                vm.tt.clear()
                if (res.code != 1) {
                    Toast(res.message);
                    return;
                }
                vm.pageNo = 1;
                vm.loadList();
                vm.loadCount();
                vm.showModal = false;
                Toast(`已成功验收${vm.checkNum}个${vm.tagId == 50 ? '无法读取数据' : '气管破损'}的探头`);
            });
        },

        loadMore() {
            const vm = this
            vm.pageNo = vm.pageNo + 1;
            vm.loadList()
        },
        createItem() {
            this.$router.push({
                path: './addUploadImgPage'
            })
        },

        loadCount() {
            const vm = this;
            vm.$http({
                type: "get",
                url: `${vm.$config.base.PROBE_BASE_URL}scrapProbe/count`,
                except: true
            }).then(res => {
                if (!res.data) {
                    Toast("网络错误");
                    return;
                }
                vm.loading = false;
                vm.countArr = res.data;
            });
        }
    }
};
</script>

<style lang="less" scoped>
@import "@/less/base.less";
.page-aftersale-upload-img {
    width: 100%;
    height: 100%;
    background: @blank;
    position: relative;
    overflow-y: auto;
    color: @black;
    padding-top: 50px;
    padding: 10px;

    .count-box {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        .count-item {
            width: 45%;
            box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
            border-radius: 10px;
            padding: 10px;
            font-size: 0.14rem;
            color: @textColor11;
            .warning-name {
                color: @themeColor;
                font-size: 0.18rem;
                font-weight: bold;
            }
            div {
                padding-bottom: 10px;
                span {
                    color: @themeColor;
                    font-weight: bold;
                }
            }
        }
    }

    .list-box {
        box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.05);
        padding: 0 5px 20px;
        border-radius: 10px;
    }
    .table-header {
        line-height: 50px;
        display: flex;
        font-size: 0.18rem;
        color: #303030;
        background: @blank;
        text-align: center;
    }
    .list-item {
        line-height: 40px;
        display: flex;
        font-size: 0.14rem;
        color: #515151;
        text-align: center;
    }

    .list-item, .table-header{
        .left {
            width: 30%;
            text-align: left;
        }
        .right {
            width: 50%;
            text-align: right;
        }
        .center {
            width: 20%;
            text-align: center;
        }
    }

    .loading-box {
        padding-top: 10px;
        text-align: center;
    }
    .create-btn {
        position: fixed;
        right: 20px;
        bottom: 50px;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        color: @themeColor;
        box-shadow: 0px 0px 10px 0px @themeColor;
        border: 2px solid @themeColor;
        background: @blank;
        font-size: 16px;
        display: flex;
        padding: 5px 11px;
        flex-direction: column;
    }

    .van-popup {
        border-radius: 10px;
        .modal-box {
            width: 300px;
            padding: 30px;
            font-size: 0.22rem;
            color: @textColor11;
            font-weight: bold;
            border-radius: 10px;
            text-align: center;
            .modal-body {
                height: 230px;
                padding: 40px 0 50px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .select-item {
                    line-height: 50px;
                    border: 2px solid rgba(0, 0, 0, 0.1);
                    border-radius: 6px;
                    &.active {
                        border-color: @themeColor;
                        color: @themeColor;
                    }
                }
                .van-field {
                    margin-top: 30px;
                    border-bottom: 1px solid @bgColor3;
                    font-size: 0.3rem;
                }
            }
            .modal-footer {
                display: flex;
                justify-content: space-between;
                .btn {
                    line-height: 50px;
                    color: @blank;
                    border-radius: 6px;
                    width: 45%;
                    border: 1px solid @textColor9;
                    &.btn-prev {
                        color: @textColor9;
                    }

                    &.btn-sure {
                        background: @bgColor3;
                        border-color: @bgColor3;
                        &.pass {
                            background: @themeColor;
                            border-color: @themeColor;
                        }
                    }
                }
                .btn-next {
                    background: @themeColor;
                    width: 80%;
                    margin: 0 auto;
                    border-color: @themeColor;
                }
            }
        }
    }
}
</style>
